import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="駐村編輯-食物記憶走讀 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "駐村編輯-食物記憶走讀",
            },
            {
              property: `og:title`,
              content: "駐村編輯-食物記憶走讀 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:description`,
              content: "駐村編輯-食物記憶走讀",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_c/c_4_2/project_c_4_2_7.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;